@import "../../styles/main.scss";

// EnterOTP Styles
.home {
  position: relative;

  .label {
    color: #1f1f1f;
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 2.6rem;
  }

  .sub__label {
    color: #5a5a5a;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .content {
    text-align: center;
  }

  .form__input {
    width: 180px;
    margin: 0 auto 10px auto;
    background-color: #f6faff;
    border: none;
    padding: 14px 20px;
    border-radius: 10px;
    font-weight: 500;
    color: #1f1f1f;
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
    border: 1px solid $primary-color;
    text-align: center;
    caret-color: $primary-color;
  }

  .resend {
    color: $primary-color;
    cursor: pointer;
  }
}
