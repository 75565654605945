@import "../../styles/main";

.termContent {
  height: 70vh;
  width: 100%;
  margin: 0 auto;
  overflow: auto;

  @include border-radius(10px);
  @include box-shadow();
}

.dynamic__content {
  background-color: #ffffff;

  td {
    border-color: #626262;
    padding: 5px;
  }
}

.accept {
  display: flex;
  font-size: 0.925rem;
  align-items: center;
  margin: 15px 0;
}
