@import "./variables";

/*----------------------------------
 Font Sizes
----------------------------------*/
.fs-10 {
  font-size: 0.625rem;
}
.fs-12 {
  font-size: 0.75rem;
}
.fs-14 {
  font-size: 0.875rem;
}
.fs-16 {
  font-size: 1rem;
}
.fs-18 {
  font-size: 1.125rem;
}
.fs-20 {
  font-size: 1.25rem;
}
.fs-22 {
  font-size: 1.375rem;
}
.fs-24 {
  font-size: 1.5rem;
}
.fs-26 {
  font-size: 1.625rem;
}
.fs-28 {
  font-size: 1.75rem;
}
.fs-30 {
  font-size: 1.875rem;
}
.fs-32 {
  font-size: 2rem !important;
}
.fs-34 {
  font-size: 2.125rem;
}
.fs-36 {
  font-size: 2.25rem;
}
.fs-38 {
  font-size: 2.375rem;
}
.fs-40 {
  font-size: 2.5rem;
}
.fs-42 {
  font-size: 2.625rem;
}
.fs-44 {
  font-size: 2.75rem;
}
.fs-46 {
  font-size: 2.875rem;
}
.fs-48 {
  font-size: 3rem;
}
.fs-50 {
  font-size: 3.125rem;
}

/*----------------------------------
 Font Weights
----------------------------------*/
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
