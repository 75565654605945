.not__found {
  position: relative;

  .image {
    max-width: 300px;
    margin: 0 auto;
  }

  h1 {
    font-size: 3rem;
    text-align: center;
  }

  h3 {
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
  }

  p {
    font-size: 1rem;
    color: #b3b3b3;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
}
