/*----------------------------------
 Colors
----------------------------------*/
$primary-color: #182bda;
$black-color: #1f1f1f;
$gray-color: #585858;
$light-gray-color: #9f9f9f;
$white-color: #fdfdfd;
$green-color: #0ebb53;
$red-color: #f33c3c;
$logo-color: #1777bb;
$gold-color: #d4af37;
$light-gray-color2: #f9f9f9;
$main-background-color: #f6faff;

/*----------------------------------
 Breakpoints
----------------------------------*/
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
