@import "../../styles/main";

.webcamContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.webcamCircle {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  border: 5px solid #ffffff;
}

.subLabel {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
}

.accept {
  display: flex;
  font-size: 0.925rem;
  align-items: center;
  margin: 15px 0;
}
