@import "../../styles/main.scss";

.select-option {
  background-color: $main-background-color;
  padding: 1rem 1rem;
  text-align: center;
  font-size: 1.2rem;
  color: $black-color;
  font-weight: 600;
  box-shadow: 0 2px 8px rgba(176, 199, 212, 0.5);
  border-radius: 1.1rem;
  margin: 1.5rem 0;

  &.checked {
    background-color: $primary-color;
    color: $white-color;
  }
}
