@import "../../styles/main.scss";

// GetCash Styles

.get__cash {
  background-color: rgba(24, 43, 218, 0.07);
  padding: 40px 20px 20px;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 20px;
  position: relative;

  .top__message {
    background-color: #020a4f;
    padding: 5px 20px 5px 15px;
    font-size: 11px;
    color: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 15px;
  }

  p {
    font-size: 17px;
    color: $gray-color;
    font-weight: 600;
    margin: 5px 0 15px 0;
  }

  .cash__image {
    max-width: 80%;
    margin: 20px auto;
  }

  h3 {
    font-size: 17px;
    color: $black-color;
    font-weight: bold;
    margin: 15px 0 15px 0;
  }

  h1 {
    font-size: 3.5rem;
    color: $black-color;
    font-weight: bold;
    line-height: 100%;
    margin: 10px 0 0px 0;
    text-shadow: 1px 2px #ffffff;
  }

  .button {
    width: 86%;
    margin: 0 auto;
  }

  .features {
    display: flex;
    justify-content: space-around;

    & > div {
      text-align: center;
      margin: 30px 0 4px 0;
    }

    img {
      width: 32px;
      margin: 5px auto;
      position: relative;
      z-index: 99999;
    }

    span {
      color: $gray-color;
      font-size: 12px;
      font-weight: 500;
      line-height: 100%;
    }
  }

  .steps {
    position: relative;

    .progress__bar {
      width: 64%;
      height: 4px;
      background: #ffffff;
      position: absolute;
      top: 58px;
      left: 17%;
    }

    .progress__percent {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 50%;
      background-color: $primary-color;
      border-radius: 10px;
    }
  }
}

// Loan Messages
.loanMessages {
  display: none;
}
