@import "../../styles/main.scss";

// CustomButton Styles

.button__container {
  position: relative;
  cursor: pointer;
}

.custom__button {
  background-color: $primary-color;
  border: none;
  padding: 12px 10px;
  width: 100%;
  color: #ffffff;
  font-weight: bold;
  font-size: 17px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.5;
  }

  span {
    margin: 0 6px;
  }

  &.large {
    padding: 17px 10px;
    font-size: 18px;
  }

  &.outline {
    background-color: transparent;
    color: $primary-color;
    border: 1px solid $primary-color;
  }
}
