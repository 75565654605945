@import "../../styles/main.scss";

/*----------------------------------
 Confirm Details CSS
----------------------------------*/
.confirmDetails__styles {
  position: relative;

  .card {
    background-color: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 3px 10px rgba(176, 199, 212, 0.6);
    margin-bottom: 20px;

    .list {
      display: flex;
      padding: 1.5rem 1.2rem;
      border-bottom: 1px solid #e5eef5;
      justify-content: space-between;
      align-items: center;
      color: $black-color;
      font-size: 13px;
      font-weight: 500;

      & > div {
        display: flex;
        align-items: center;
      }
    }

    .title {
      opacity: 0.6;
    }

    .value {
      margin-right: 2px;
    }

    .address {
      overflow: hidden;
      width: 8rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
