// Select Loan CSS
.selectLoan__page {
  position: relative;
  height: auto;
  padding-bottom: 100px;

  .label {
    font-size: 22px;
    line-height: 32px;
    font-weight: 500;
  }

  .loan-lists {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 20px 0;
  }

  .content {
    h3 {
      font-weight: 600;
      font-size: 18px;
      color: #1f1f1f;
    }

    p {
      font-size: 13px;
      color: #585858;
      margin: 6px 0;
    }

    .loan-details {
      display: flex;
      margin-top: 30px;

      & div:first-child {
        margin-right: 30px;
      }
    }

    .value {
      font-weight: bold;
      color: #1f1f1f;
      font-size: 14px;
    }

    .label {
      font-size: 13px;
      color: #585858;
      font-weight: 500;
      line-height: 100%;
      margin-top: 5px;
    }
  }

  .image {
    width: 136px;
    padding-left: 15px;
  }

  .single {
    flex-direction: column-reverse;

    .content,
    .image {
      width: 100%;
      text-align: center;

      img {
        max-width: 150px;
        margin: 0 auto;
        padding-bottom: 22px;
      }
    }

    h3 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }

    .loan-details {
      justify-content: space-between;
      margin-top: 20px;
    }

    .value {
      font-size: 16px;
      font-weight: 600;
    }

    .label {
      font-size: 14px;
    }
  }
}
