// Tabs
.tabs {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: -4px;
  margin-right: -4px;

  button {
    background-color: #ffffff;
    padding: 12px 10px;
    border: 1px solid #9f9f9f;
    margin: 3px;
    color: #9f9f9f;
    border-radius: 30px;
    font-weight: 500;
    flex: 1;
    text-align: center;

    &.active {
      background-color: #182bda;
      color: #ffffff;
      border-color: #182bda;
    }
  }
}
