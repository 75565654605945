@import "./variables";

/*----------------------------------
 Breakpoints
----------------------------------*/
@mixin breakpoint-min($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin breakpoint-max($point) {
  @media (max-width: $point) {
    @content;
  }
}

@mixin breakpoint-min-max($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

html {
  font-size: 13px !important;
}
