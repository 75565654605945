.data-safety {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.8rem 0;

  .safety-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    font-size: 1rem;
    font-weight: 500;
    color: #696969;
  }

  .image {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}
