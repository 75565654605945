// EMI Repayment CSS
.emiRepayment {
  position: relative;
  background-color: #ffffff;
  border-radius: 18px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  // Cash
  .cash {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dedede;
    margin-bottom: 15px;
    padding-bottom: 15px;

    h3 {
      font-weight: 500;
      color: #585858;
      font-size: 18px;
      margin-bottom: 7px;

      span {
        color: #1f1f1f;
        font-weight: bold;
        margin-left: 7px;
      }
    }

    p {
      font-size: 14px;
      color: #7e7d7d;

      span {
        font-weight: 600;
      }
    }
  }

  // EMIs List
  .emis {
    margin-bottom: 20px;

    .emiList {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 18px 0;
    }

    .emiAmount {
      display: flex;
    }

    .number {
      width: 20px;
      height: 20px;
      background-color: #efefef;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 500;
      color: #1f1f1f;
      margin-right: 10px;
      margin-top: 4px;
    }

    .amount {
      font-size: 16px;
      color: #1f1f1f;
      font-weight: 500;
    }

    .date {
      color: #9f9f9f;
      font-size: 12px;
    }

    .repay {
      border: none;
      background: rgba(24, 43, 218, 0.1);
      color: #182bda;
      padding: 12px 18px;
      border-radius: 7px;
      font-weight: 600;
      font-size: 15px;
    }

    .emiListOverdue {
      .amount {
        font-size: 24px;
        font-weight: 600;
      }

      .date {
        color: #f33c3c;
        font-size: 15px;
      }

      .overdueDay {
        background: rgba(243, 60, 60, 0.1);
        padding: 2px 8px;
        color: #f33c3c;
        font-size: 12px;
        border-radius: 4px;
        margin: 10px 0 8px 0;
        font-weight: 500;
        text-align: center;
        display: inline-block;
      }
    }
  }

  // Button
  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 6px 0;

    .btn {
      padding: 12px 20px;
      border-radius: 10px;
      font-size: 16px;
      background: #e7e9fb;
      color: #182bda;
      font-weight: 600;
      border: 2px solid #e7e9fb;
      width: 44%;
      text-align: center;
    }

    .btnActive {
      background-color: #182bda;
      border-color: #182bda;
      color: #ffffff;
    }
  }
}

// Delay Repayment
.delayRepayment {
  background: rgba(243, 60, 60, 0.1);
  padding: 30px 15px 6px 15px;
  color: #f33c3c;
  font-size: 13px;
  text-align: center;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  margin-top: -24px;
}
