@import "../../styles/main.scss";

/*-----------------------------------
 Contact Us CSS
-----------------------------------*/
.contact__us {
  position: relative;

  .list {
    display: flex;
    padding: 1.2rem 1rem;
    border-radius: 1rem;
    align-items: center;
    margin: 24px 0;
    @include box-shadow;

    .icon {
      width: 46px;
      height: 46px;
      background-color: rgba(24, 43, 218, 0.1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 65px);
      padding-left: 15px;
    }

    h5 {
      color: $black-color;
      font-weight: 600;
      font-size: 1.25rem;
    }

    span {
      color: $gray-color;
      font-weight: 500;
      font-size: 1rem;
    }

    .arrow {
      margin-right: -1.5rem;
      position: relative;
      top: 1px;
    }
  }
}
