.enterName {
  position: relative;

  h2 {
    font-size: 20px;
    color: #5a5a5a;
    font-weight: 500;
    margin-bottom: 2.4rem;
  }
}
