@import "../../styles/main.scss";

.dropdown {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autoComplete {
  position: absolute;
  background-color: $main-background-color;
  z-index: 999999;
  width: 100%;
  box-shadow: 0 5px 10px rgba(176, 199, 212, 0.6);
  border-bottom-right-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
  top: 4.5rem;
  max-height: 200px;
  overflow-y: scroll;

  li {
    color: #5a5a5a;
    font-size: 1rem;
    font-weight: 500;
    padding: 14px 20px;
    border-top: 1px solid #dddddd;
  }
}
