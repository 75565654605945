@import "../../styles/main.scss";

// Header
.header {
  text-align: center;
  padding: 2.2rem 0 3rem 0;

  img {
    width: 210px;
    margin: 0 auto;
  }
}
