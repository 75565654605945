@import "./variables";

@mixin flexbox($justify: center, $align: center, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin center($type: absolute) {
  position: $type;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin border-radius($radius: 4px) {
  border-radius: $radius;
}

@mixin transition($property: all, $duration: 0.3s, $ease: ease-in-out) {
  transition: $property $duration $ease;
}

@mixin box-shadow($x: 0px, $y: 3px, $blur: 10px, $color: rgba(176, 199, 212, 0.6)) {
  box-shadow: $x $y $blur $color;
}
