@import "../../styles/main.scss";

.pan__aadhaarVerify {
  position: relative;

  .aadhaarImage {
    text-align: center;

    img {
      width: 200px;
      margin: 0 auto;
    }

    h3 {
      font-weight: 600;
      margin: 20px 0;
    }
  }
}
