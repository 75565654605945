// Layout CSS
.layout {
  display: flex;
  min-height: 100vh;
  height: auto;

  .content {
    width: 50%;
    background-color: #f6f9fa;
    padding: 30px;

    .logo {
      width: 260px;
      padding: 0px 0 10px 0;
      margin: 0 auto;
    }

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin: 5px 0 40px 0;
      text-align: center;

      span {
        color: #2264dc;
        font-size: 32px;
        padding-left: 3px;
      }
    }
  }

  .listItem {
    margin: 2px 0;
    background-color: #ffffff;
    padding: 15px 20px;
    border-radius: 10px;
    height: 100%;
    text-align: center;

    img {
      width: 44px;
      margin: 0 auto;
    }

    h4 {
      font-size: 15px;
      margin: 12px 0 0 0;
      font-weight: 600;
      line-height: 130%;
    }
  }

  .reviews {
    width: 60%;
    margin: 40px auto 20px auto;
  }

  .screen {
    width: 50%;
    background-color: #dcf0f4;
    padding: 20px;

    .innerScreen {
      position: relative;
    }

    .frame {
      width: 390px;
      height: 620px;
      padding: 16px 0;
      margin: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    iframe {
      width: 327px;
      height: 585px;
      border: none;
      background: #fff;
      border-radius: 25px;
      padding: 0px;
      position: relative;
      left: 32px;
    }
  }
}
