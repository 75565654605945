@import "../../styles/main";

.bottomDrawer {
  :global(.css-dl7nww-MuiPaper-root-MuiDrawer-paper) {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
  }
}

.mandateProcessModal {
  padding: 2rem 2rem 5rem 2rem;
  text-align: center;
  background-color: $main-background-color;

  p {
    font-size: 1.2em;
    font-weight: 500;
    color: $gray-color;
  }

  img {
    margin: 0 auto;
    width: 100%;
  }

  h3 {
    color: $primary-color;
    margin: 3.5rem 0rem 1rem 0rem;
    font-size: 24px;
    font-weight: 600;
  }
}

.edit__bank {
  text-align: center;
  margin-top: 12px;

  a {
    color: $gray-color;
    font-weight: 500;
  }
}
