@import "../../styles/main.scss";

// EnterAadhaar.module.scss

.aadhaar__styles {
  position: relative;

  .kycOptions {
    display: flex;
    flex-direction: column;

    &.down {
      flex-direction: column-reverse;
    }
  }

  .selectOption {
    position: relative;
    cursor: pointer;
    margin: 10px 0;
    background-color: $main-background-color;
    border-radius: 1rem;
    padding: 1rem 1.8rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 2px solid $main-background-color;

    &.selected {
      border-color: $primary-color;
      background-color: #e9f4ff;
    }

    .text {
      font-size: 1.1rem;
      color: #7a7a7a;
      margin-left: 1rem;
      font-weight: 500;
    }

    .bigText {
      color: $black-color;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .selectDigilocker {
    display: flex;
    align-items: center;

    .verifyImage {
      margin-right: 10px;
    }
  }

  .recommended {
    background-color: #4caf50;
    padding: 2px 10px;
    font-size: 0.9rem;
    position: absolute;
    color: #ffffff;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 5px;
    top: -2px;
    right: -2px;
    z-index: 999;
  }

  .form-control {
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 1rem;
  }

  .check {
    position: absolute;
    top: 19px;
    right: 15px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .notReceivedMsg {
    text-align: center;
    font-size: 1rem;
    color: #5a5a5a;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .resend {
    color: $primary-color;
    cursor: pointer;
  }

  .otpInputs {
    margin: 15px 0;

    & > div {
      justify-content: center;
    }

    input {
      border: 2px solid #bcd8ff;
      background-color: #ffffff;
      border-radius: 5px;
      // width: 40px !important;
      height: 50px;
      font-family: "Poppins", sans-serif;
      font-size: 1.2rem;
      color: $black-color;
      font-weight: 600;
      padding: 0;
      flex: 1;

      &:focus {
        outline: none;
        border-color: $primary-color;
      }
    }
  }
}
