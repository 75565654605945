.info-message {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}

.text {
  font-size: 12px;
  margin: 0px 0 0 5px;
  font-weight: normal;
  color: #696969;
  line-height: 100%;
  line-height: 1.2rem;
}
