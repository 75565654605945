@import "./variables";

/*----------------------------------
 Margin Top
----------------------------------*/
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mt-5 {
  margin-top: 5rem !important;
}

/*----------------------------------
 Margin Right
----------------------------------*/
.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mr-3 {
  margin-right: 3rem !important;
}
.mr-4 {
  margin-right: 4rem !important;
}
.mr-5 {
  margin-right: 5rem !important;
}

/*----------------------------------
 Margin Bottom
----------------------------------*/
.mb-0 {
  margin-bottom: 0rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 4rem !important;
}
.mb-5 {
  margin-bottom: 5rem !important;
}

/*----------------------------------
 Margin Left
----------------------------------*/
.ml-0 {
  margin-left: 0rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.ml-3 {
  margin-left: 3rem !important;
}
.ml-4 {
  margin-left: 4rem !important;
}
.ml-5 {
  margin-left: 5rem !important;
}

/*----------------------------------
 Margin Top & Bottom
----------------------------------*/
.my-1 {
  margin: 1rem 0 !important;
}
.my-2 {
  margin: 2rem 0 !important;
}
.my-3 {
  margin: 3rem 0 !important;
}
.my-4 {
  margin: 4rem 0 !important;
}
.my-5 {
  margin: 5rem 0 !important;
}

/*----------------------------------
 Margin Left & Right
----------------------------------*/
.mx-1 {
  margin: 0 1rem !important;
}
.mx-2 {
  margin: 0 2rem !important;
}
.mx-3 {
  margin: 0 3rem !important;
}
.mx-4 {
  margin: 0 4rem !important;
}
.mx-5 {
  margin: 0 5rem !important;
}
