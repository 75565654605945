@import "../../styles/main";

.bankListCard {
  position: relative;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  box-shadow: 0 3px 10px rgba(176, 199, 212, 0.6);
  border-radius: 1rem;
  cursor: pointer;
  margin: 15px 0;
  border: 3px solid $main-background-color;

  &.active {
    border-color: $primary-color;
  }

  .bankInfo {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    .logo {
      margin-right: 15px;
      width: 40px;
    }

    img {
      width: 100%;
    }

    .name {
      font-size: 1.3rem;
      font-weight: 500;
    }
  }

  .accountInfo {
    margin: 10px 0;

    p {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0px;
      line-height: 100%;
    }

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: $gray-color;
    }

    &.verified {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .accountVerifiedBottom {
      display: flex;
      align-items: center;

      img {
        width: 16px;
      }

      span {
        font-size: 0.8rem;
        margin-left: 3px;
        font-weight: 500;
      }
    }
  }

  .accountVerified {
    position: absolute;
    top: -1px;
    right: -1px;
    display: flex;
    align-items: center;
    padding: 0.4rem 1rem;
    background-color: $primary-color;
    border-top-right-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;

    img {
      width: 15px;
      height: 15px;
    }

    span {
      color: $white-color;
      font-size: 0.8rem;
      margin-left: 3px;
      font-weight: 500;
    }
  }
}

.addNewAccount {
  text-align: right;
  margin: 0.5rem 0 3rem 0;
  font-weight: 600;
  font-size: 1.1rem;
}

.footer {
  background: #f6faff;
  bottom: 0;
  left: 50%;
  max-width: 480px;
  padding: 10px 15px;
  position: fixed;
  width: 100%;
  transform: translate(-50%, 0px);
}
